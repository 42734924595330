import './footer.css';

const Footer = () => {
  return (
    <div className='footer'>
      <p> 2023 | Tüm Hakları Saklıdır</p>
    </div>
  );
};

export default Footer;