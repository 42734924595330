import "./navbar.css";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { navbarData } from "./navbarData";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenu } from "../../redux/features/menuSlice";

export default function Navbar() {
  const dispatch = useDispatch();
  const menuToggle = useSelector((state) => state.menu.menuToggle);

  const showSidebar = () => dispatch(toggleMenu());

  return (
    <>
      <IconContext.Provider value={{ color: "black" }}>
      <div className="navbar">
            <Link className="menu-bars">
              <AiIcons.AiOutlineMenu onClick={showSidebar} color={"#00A9B1"} />
            </Link>
          </div>
          <div className={menuToggle ? "navbar-background" : null}>
            <nav className={menuToggle ? "nav-menu active" : "nav-menu"}>
              <ul className="nav-menu-items" onClick={showSidebar}>
                <li className="navbar-toggle">
                  <Link to="#" className="menu-bars">
                    <AiIcons.AiOutlineClose />
                  </Link>
                </li>

                {navbarData.map((item, index) => {
                  return (
                    <li key={index} className={item.cName}>
                      <Link to={item.path}>
                        {item.icon}
                        <span>{item.title}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
      </IconContext.Provider>
    </>
  );
}
