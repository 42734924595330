import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ contentData }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Aylara Göre Ortalama Puan',
      },
      scales: {
        yAxes: [{
          display: true,
          ticks: {
              beginAtZero: true,
              steps: 10,
              stepValue: 5,
              max: 100
          }
      }]
      }
    },
  };
  
  const labels = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz','Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık',];
  
  const data = {
    labels,
    datasets: contentData,
  };

  return (
    <Line options={options} data={data} />
  )
}

export default LineChart;