import './answer.css'
import { useDispatch } from 'react-redux';
import { toggleAnswers } from '../../redux/features/selectedAnswersSlice';

const Answer = ({ answer, chosen, questionId, index }) => {
  const dispatch = useDispatch();
  
  const handleClick = () => {
    dispatch(toggleAnswers({ questionId: questionId, option: (answer.option).toUpperCase() }));
  }
  
  return (
    <div 
      className="answer-container"
      key={index}
      id={`answer-qn:${questionId}-ao:${answer.option}`} 
      onClick={() => handleClick()}>
      <div className="ac-check-box" id={`answer-qn:${questionId}-ao:${answer.option}`}>
        <div className={`ac-cb-box ${chosen ? "ac-cb-box-checked": "ac-cb-box-unchecked"}`}>
          <p>{(answer.option).toUpperCase()}</p>
        </div>
      </div>
      <div className="ac-text-box" id={`answer-qn:${questionId}-ao:${answer.option}`}>
        <p id={`answer-qn:${questionId}-ao:${answer.option}`}>{answer.answer}</p>
      </div>
    </div>
  )
}

export default Answer;