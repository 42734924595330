export const getAges = (data) => {
  return [...new Set(data.map((i) => i.data.demographicAnswers.age))]
};

export const getGenders = (data) => {
  return [...new Set(data.map((i) => i.data.demographicAnswers.gender))]
};

export const getGraduates = (data) => {
  return [...new Set(data.map((i) => i.data.demographicAnswers.graduation))]
};

export const getCities = (data) => {
  return [...new Set(data.map((i) => i.data.demographicAnswers.city))]
}

export const getYears = (data) => {
  return [...new Set(data.map((i) => String(new Date(i.data.createdAt.seconds * 1000 + i.data.createdAt.nanoseconds / 1000000).getFullYear())))]
}

export const getParentsEducation = (data) => {
  return [...new Set(data.map((i) => i.data.demographicAnswers.parents))]
}