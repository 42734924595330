import PieChart from "../../pieChart/pieChart";

import * as AiIcons from "react-icons/ai";

import { useState, useEffect } from 'react';

import SingleSelectPopup from '../../popup/singleSelectPopup/singleSelectPopup';

// import { useSelector } from 'react-redux';

import { getGenders } from '../../../lib/getTypes';
import { getAges } from '../../../lib/getTypes';
import singleComparison from '../../../lib/singleComparison';
import TwoParametersTable from "../../tables/twoParametersTable/twoParametersTable";

const GenderAndAgeCompare = ({ overallData }) => {
  // const overallData = useSelector((state) => state.overallResults.overallResults);

  const [ visibility, setVisibility ] = useState(false);
  const [ labels, setLabels ] = useState([]);
  const [ currentGender, setCurrentGender ] = useState("tümü");
  const [ genderData, setGenderData ] = useState({});
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    let gender = overallData;
    if(currentGender !== "tümü") {
      gender = gender.filter((e) => e.data.demographicAnswers.gender === currentGender);
    }

    setLabels(getAges(gender));

    const dataForChart = singleComparison({ mainType: 'gender', mainFilter: currentGender, secondType: 'age', labels: getAges(gender), overallData })
    if(dataForChart.data.length > 0) {
      setGenderData(dataForChart);
      setLoading(false);
    }
  }, [overallData, currentGender])

  const togglePopup = () => {
    setVisibility(!visibility);
  };

  const handleSelect = ( gender ) => {
    setCurrentGender(gender);
    setVisibility(false);
  }

  if(loading) {
    return null
  }

  return (
    <div className='compare-container'>
      <SingleSelectPopup 
        visibility={ visibility }
        togglePopup={ togglePopup }
        handleSelect={ handleSelect }
        data={ getGenders(overallData) }
        currentData={ currentGender }
      />
      <p>Bu grafikte Türkiye genelinde cinsiyet ve yaşlara göre sitemizde bulunan istatistik okuryazarlığı belirleme testinden alınan ortalama puanlarını inceleyebilirsiniz.</p>
      <div className='cc-filter-box'>
        <div className='cc-fb-element'>
          <p>{ currentGender === "tümü" ? "tüm cinsiyetlerde" : currentGender }</p>
        </div>
        <div className='cc-fb-add-element' onClick={togglePopup}>
          <AiIcons.AiOutlineReload />
        </div>
      </div>
      <div className='chart-container'>
        <PieChart 
          labels = { labels } 
          contentData = { genderData }
        />
      </div>
      <div className="cc-table-container">
        <TwoParametersTable 
          columns={["Yaş", "Katılım Sayısı", "Ortalama Puan"]}
          parameters={["gender", "age"]}
          currentData={ currentGender }
          labels={ labels }
          data={ overallData }/>
      </div>
    </div>
  )
};

export default GenderAndAgeCompare;