import './pieChart.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = ({ labels, contentData }) => {
  const data = {
    labels: labels,
    datasets: [contentData],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: '#fff',
        font: {
          weight: 'bold',
          size: 15,
        }
      }
    }
  };
  return (
    <div className='pie-container'>
      <Pie data={data} options={options}/>
    </div>
  )
};

export default PieChart;
