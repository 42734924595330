import "./progressBar.css";
import { useSelector } from "react-redux";
import { useLayoutEffect, useState } from "react";

const ProgressBar = () => {
  const [scrollPosition, setPosition] = useState(0);

  useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset)
    }
    window.addEventListener('scroll', updatePosition);
    updatePosition();
  }, []);

  const selectedAnswers = useSelector((state) => state.selectedAnswers.selectedAnswers).length;
  const totalQuestions = useSelector((state) => state.questions.questions).length;

  return (
    <div className={`progress-bar ${scrollPosition > 79.5 && "progress-bar-fix"}`}>
      <div className={`progress-bar-progress`} style={{width: `${(selectedAnswers/totalQuestions)*100}%`}}></div>
      <div className="deneme">{`${selectedAnswers} / ${totalQuestions}`}</div>
    </div>
  );
}

export default ProgressBar;