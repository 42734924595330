import Popup from "../popup"
const singleSelectPopup = ({ visibility, togglePopup, handleSelect, data, currentData}) => {
  return (
    <Popup visibility={ visibility } togglePopup={ togglePopup }>
      <div className="pc-cb-title-box">
        <p className="pc-cb-tb-title">Cinsiyet Seç</p>
      </div>
      <div className="pc-cb-contents">
          <div 
          className={`pc-cb-c-element ${currentData === "tümü" && 'current-element'}`}
          onClick={() => handleSelect("tümü")}>
          <p>tüm cinsiyetler</p>
        </div>
        {data.map((element, index) => (
          <div 
            key={index}
            className={`pc-cb-c-element ${currentData === element && 'current-element'}`}
            onClick={() => handleSelect(element)}>
            <p>{element}</p>
          </div>
        ))}
      </div>
    </Popup>
  )
}

export default singleSelectPopup