import { configureStore } from "@reduxjs/toolkit";
import menuReducer from "./features/menuSlice";
import selectedAnswersReducer from "./features/selectedAnswersSlice";
import questionsReducer from "./features/questionsSlice";
import resultReducer from "./features/resultSlice";
import overallResultsReducer from './features/overallResultsSlice';
import timerForRates from "./features/timerForRates";

export const store = configureStore({
  reducer: {
    menu: menuReducer,
    selectedAnswers: selectedAnswersReducer,
    questions: questionsReducer,
    result: resultReducer,
    overallResults: overallResultsReducer,
    timerForRates: timerForRates,
  },
});