import Popup from "../popup";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

import { useState } from 'react';

const MultipleSelectPopup = ({type, info, data, togglePopup, handleSelect, handleDelete, currentData, visibility, title, allOptionText}) => {
  const [search, setSearch] = useState('');
  
  function toTitleCaseTR(str) {
    return str.toLocaleLowerCase('tr-TR').replace(/(?:^|\s|,|;|!|:|-|\.|\?)[a-z0-9ğçşüöı]/g, function(match) {
        return match.toLocaleUpperCase('tr-TR');
    });
  }

  return (
    <Popup visibility={visibility} togglePopup={togglePopup}>
      <div className='pc-cb-title-box'>
        <p className='pc-cb-tb-title'>{title}</p>
        <div className='pc-cb-tb-search-box'>
          <input type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} value={search} />
          {search !== "" ? <AiIcons.AiOutlineClose color='gray' style={{cursor: "pointer"}} onClick={() => setSearch('')}/> : <FaIcons.FaSearch color='gray'/>}
        </div>
      </div>
      {info && (
        <div className='pc-cb-tb-information-box'>
          <FaIcons.FaExclamation color="#007F87" />
          <p>{ info }</p>
        </div>
      )}
      <div className="pc-cb-contents">
        {
          allOptionText && (
            <div className={`pc-cb-c-element ${currentData.find((e) => e === "tümü") && 'current-element'}`} onClick={() => currentData.find((e) => e === "tümü") ? handleDelete("tümü") : handleSelect("tümü")}>
              <p>
                { allOptionText }
              </p>
            </div>
          )
        }
        {data.map((item, index) => (
          toTitleCaseTR(item).includes(toTitleCaseTR(search)) && (
          <div 
            key={ index } 
            className={`pc-cb-c-element ${currentData.find((e) => e === item) && 'current-element'}`}
            onClick={() => currentData.find((e) => e === item) ? handleDelete(item) : handleSelect(item)}
          >
            <p>
              {item}
            </p>
          </div>
        )))}
      </div>
    </Popup>
  )
}

export default MultipleSelectPopup;