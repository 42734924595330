import './rates.css'
import { useRef, useState } from 'react'
import { timerToggle } from '../../redux/features/timerForRates'
import { useDispatch, useSelector } from 'react-redux'
import RatesSlice from './ratesSlice/ratesSlice'
import { getGenders, getCities, getAges } from '../../lib/getTypes'
import getAttendanceCount from '../../lib/getAttendanceCount'
import RatesChartComponent from './ratesChartComponent/ratesChartComponent'
import CircleLoader from '../circleLoader/circleLoader'

const Rates = ({ resultsData }) => {
  const ratesRef = useRef(null)
  const [isMouseDown, setIsMouseDown] = useState(false)
  
  const ratesData = [
    <RatesChartComponent 
      results={getAttendanceCount(resultsData, "gender", getGenders(resultsData))}
      title="Cinsiyete Göre Katılım Sayısı"
      />,
    <RatesChartComponent 
      results={getAttendanceCount(resultsData, "city",  getCities(resultsData))} 
      title="Şehirlere Göre Katılım Sayısı"
      />,
    <RatesChartComponent 
      results={getAttendanceCount(resultsData, "age", getAges(resultsData))}
      title="Yaşlara Göre Katılım Sayısı"
    />
  ]

  const dispatch = useDispatch()
  const { count } = useSelector(state => state.timerForRates)
  const onHover = (element) => {
    setIsMouseDown(true)
    dispatch(timerToggle(true))
  }

  const onLeave = (element) => {
    setIsMouseDown(false)
    dispatch(timerToggle(false))
  }

  return (
    <div 
      ref={ratesRef} 
      className="rates-container" 
      onMouseDown={(e) => onHover(e.target)}
      onMouseUp={(e) => onLeave(e.target)}
      onTouchStart={(e) => onHover(e.target)} 
      onTouchEnd={(e) => onLeave(e.target)}
      >
      <div className={`rates-slices-container ${isMouseDown && "rates-slices-container-none"}`}>
        {ratesData.map((rate, index) => (
          <RatesSlice key={index} ratesData={ ratesData } index={ index } isMouseDown={ isMouseDown }/>
        ))}
      </div>
      <div className='aa'>
        {resultsData.length > 0 
          ? ratesData[count]
          : <CircleLoader />
        }
      </div>

    </div>
  )
}

export default Rates;