import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";

export const navbarData = [
  {
    title: "Ana Sayfa",
    path: "/",
    icon: <AiIcons.AiFillHome />,
    cName: "nav-text"
  },
  {
    title: "Sonuçlar",
    path: "/results",
    icon: <FaIcons.FaChartBar />,
    cName: "nav-text"
  },
  // {
  //   title: "Kaynaklar",
  //   path: "/about",
  //   icon: <IoIcons.IoIosPaper />,
  //   cName: "nav-text"
  // },
  // {
  //   title: "Bilgi Bankası",
  //   path: "/about",
  //   icon: <FaIcons.FaBookReader />,
  //   cName: "nav-text"
  // },
];
