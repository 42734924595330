import './results.css';
import CityCompare from '../../components/generalResult/cityCompare/cityCompare';
import GenderAndAgeCompare from '../../components/generalResult/genderAndAgeCompare/genderAndAgeCompare';
import CitiesResults from '../../components/generalResult/citiesResults/citiesResults';
import YearCompare from '../../components/generalResult/yearCompare/yearCompare';
import ParentsResults from '../../components/generalResult/parentsResults/parentsResults';
import CircleLoader from '../../components/circleLoader/circleLoader';

import React from 'react';

import { useEffect, useState } from 'react';

import {collection, getDocs} from "firebase/firestore"

import db from '../../firebase/connection'

const Results = () => {
  const [results, setResults] = useState([])
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    const getResults = async () => {
      const querySnapshot = await getDocs(collection(db, 'results'))
      setResults(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
      setLoading(false)
    }
    getResults()
  }, [])

  return (
    <div className="results-container">
      {
        loading 
        ? <CircleLoader />
        : 
        <div className='rc-content-box'>
          <div className='rc-cb-title-box'>
            {
              results.length > 0
              ? <p className='rc-cb-tb-title'>Çalışma sonuçlarını buradan inceleyebilirsiniz</p>
              :  <p className='rc-cb-tb-title'>Henüz bir sonuç yok</p>
            }
          </div>
          <CityCompare overallData={ results }/>
          <hr />
          <GenderAndAgeCompare overallData={ results }/>
          <hr />
          <CitiesResults overallData={ results }/>
          <hr />
          <ParentsResults overallData={ results }/>
          <hr />
          <YearCompare overallData={ results }/>
        </div>
      }

    </div>
  );
};

export default Results;