import Data from '../data.json'

let result = 0;
const calculatePersonalResult = async ({ questions, answers }) => {
  for (var i = 0; i < questions.length; i++) {
    if(!answers[i]) {
      // no answer
    } else {
      const question = questions.find((question) => question.id === answers[i].questionId)
      const answer = answers.find((element) => element.questionId === question.id)

      if(question.data.correctAnswer === answer.option) {
        // correct answer
        result += (100/questions.length);

      } else {
        // wrong answer
      }
    }
  }

  return result;
};

export default calculatePersonalResult;