import './test.css';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { addQuestions } from '../../redux/features/questionsSlice';
import { clearAnswers } from '../../redux/features/selectedAnswersSlice';
import { clearResult } from '../../redux/features/resultSlice';

import {collection, query, onSnapshot,addDoc, Timestamp } from "firebase/firestore"
import db from '../../firebase/connection'

import Demographic from '../../components/demographic/demographic';
import Question from '../../components/question/question';
import ProgressBar from '../../components/progressBar/progressBar';
import PersonalBar from '../../components/barChart/personalBar/personalBar';

import calculatePersonalResult from '../../lib/calculatePersonalResult';
import deleteSpacesAndShrinkText from '../../lib/deleteSpacesAndShrinkText';

const Test = () => {
  const dispatch = useDispatch();

  const [testFinished, setTestFinished] = useState(false);
  const [personalResult, setPersonalResult] = useState(0);
  const [questions, setQuestions] = useState([])
  const [results, setResults] = useState([])
  const [avarage, setAvarege] = useState(0)
  const [loading, setLoading] = useState(true)

  const scollToRef = useRef();

  const answers = useSelector((state) => state.selectedAnswers.selectedAnswers);
  const demographicAnswers = useSelector((state) => state.result.result.demographicAnswers)

  useEffect(() => {
    const checkValuesOfDemographicAnswers = Object.values(demographicAnswers).every((value) => value);

    if(checkValuesOfDemographicAnswers) {
      const studentDegree = deleteSpacesAndShrinkText(demographicAnswers.studentDegree)
      if(!studentDegree) return
      const q = query(collection(db, `questions-${studentDegree}`))
      onSnapshot(q, (querySnapshot) => {
        if(querySnapshot.docs.length === 0) {
          setLoading(false)
          return
        }
        setQuestions(querySnapshot.docs.sort((a,b) => a.data().questionNumber - b.data().questionNumber).map(doc => ({
          id: doc.id,
          data: doc.data()
        })))
      })

      const r = query(collection(db, 'results'))
      onSnapshot(r, (querySnapshot) => {
        setResults(querySnapshot.docs.map(doc => ({
          results: doc.data().result
        })))
      });
    }
  }, [demographicAnswers])

  useEffect(() => {
    if(results.length > 0) {
      const total = results.reduce((acc, result) => {
        return acc + result.results
      }, 0)
      setAvarege(total / results.length)
    }
  }, [results])

  useEffect(() => {
    if(questions.length > 0) {
      dispatch(addQuestions(questions))
      setLoading(false)
    } 
  }, [questions, dispatch])

  useEffect(() => {
    if(!loading) {
      scollToRef.current.scrollIntoView({
        behavior: "smooth",
      })
    }
  }, [loading])

  useEffect(() => {
    return () => {
      dispatch(clearResult());
      dispatch(clearAnswers());
      setLoading(true);
    };
  }, [dispatch])

  const handleTestFinish = () => {
    calculatePersonalResult({ questions, answers }).then((result) => {
      setTestFinished(true);
      setPersonalResult(result);

      addDoc(collection(db, "results"), {
        result: result,
        demographicAnswers: demographicAnswers,
        createdAt: Timestamp.now()
      });
    });
  };

  useEffect(() => {
    if(testFinished) {
      scollToRef.current.scrollIntoView({
        behavior: "smooth",
      })
    }
  }, [testFinished])

  return (
    <div className="test-container">
      <p className='tc-info-text'>Teste başlamadan önce demografik soruları cevaplayın</p>
      <Demographic />
      { !loading &&
        <div className='tc-qestions-container' ref={scollToRef}>
          {questions.length === 0 && !loading
            ? 
            <>
              <p className='tc-info-text'><strong>{demographicAnswers.studentDegree}</strong> eğitim düzeyi için henüz bir ölçek bulunmamaktadır.</p>
            </> 
            :
            <>
              <p className='tc-info-text'>Test sorularını cevaplayabilirsiniz</p>
              <p className='tc-info-text'>Bu gruptaki soruların sadece bir doğru cevabı vardır. Eğer cevabı bilmiyorsanız soruyu boş bırakınız.</p>
              <ProgressBar/>
              {
                questions.map((question, index) => {
                  return (
                    <Question question={question} number={index + 1} key={index}/>
                  )
                })
              }
              {!testFinished && <button onClick={() => handleTestFinish()} className='tc-button'>Testi Bitir</button>}
            </>
          }
        </div>
      }
      {
        testFinished &&
        <div className='tc-result-container' ref={scollToRef}>
          <p>Test Sonucun: {(personalResult).toFixed(2)}</p>
          <PersonalBar result={ personalResult } avarage = { avarage } />
          <Link to={"/results"} className='tc-button'>Çalışma Sonuçlarını İncele</Link>
        </div>
      }
    </div>
  );
};

export default Test