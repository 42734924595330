import '../tables.css';
import { useState, useEffect } from 'react';
import { getGenders } from '../../../lib/getTypes';

const TwoParametersTable = ({ columns, parameters, currentData, labels, data }) => {
  const [ loading, setLoading ] = useState(true);
  const [ tableData, setTableData ] = useState([]);

  useEffect(() => {
    setTableData([]);
    let a;
    if(currentData === "tümü") {
      a = data;
    } else {
      a = data.filter((e) => e.data.demographicAnswers[parameters[0]] === currentData)
    }

    labels.map((label) => {
      setTableData(tableData => [...tableData, {
        label: label,
        length: a.filter((e) => e.data.demographicAnswers[parameters[1]] === label).length,
        score: (a.filter((e) => e.data.demographicAnswers[parameters[1]] === label).reduce((acc, curr) => acc + curr.data.result, 0) / a.filter((e) => e.data.demographicAnswers[parameters[1]] === label).length).toFixed(1)
      }])
    })
    setLoading(false);
  }, [labels])

  if(loading) return null;

  return (
    <div className='table-container'>
      <table>
        <thead>
          <tr>
            <th colSpan={columns.length}>{ currentData }</th>
          </tr>
        </thead>
        <tbody>
          <tr className='tc-columns'>
            {columns.map((column, i) => (
              <th key={i}>{column}</th>
            ))}
          </tr>
          {tableData.sort((a,b) => a.label - b.label).map((row, i) => (
            <tr key={i}>
              <td>{row.label}</td>
              <td>{row.length}</td>
              <td>{row.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TwoParametersTable;