import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  result: {
    score: 0,
    demographicAnswers: {
      studentDegree: '',
      age: '',
      gender: '',
      department: '',
      grade: '',
      gpa: '',
      parents: '',
      mediaStatisticsTrust: '',
      dailyStatisticsReading: '',
      city: ''
    }
  },
}

export const resultSlice = createSlice({
  name: 'result',
  initialState,
  reducers: {
    addScore(state, action) {
      state.result.score = action.payload.score;
    },
    addDemographicAnswers(state, action) {
      state.result.demographicAnswers = action.payload;
    },
    clearResult(state) {
      state.result = initialState.result;
    }
  },
});

export const { addScore, addDemographicAnswers, clearResult } = resultSlice.actions;
export default resultSlice.reducer;