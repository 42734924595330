import "./question.css"
import Answer from "../answer/answer";
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const Question = ({ question, number }) => {
  const [currentOption, setCurrentOption] = useState(null);
  const selectedAnswers = useSelector((state) => state.selectedAnswers.selectedAnswers);

  useEffect(() => {
    const currentAnswer = selectedAnswers.find((answer) => answer.questionId === question.id);
    if (currentAnswer) {
      setCurrentOption(currentAnswer.option);
    } else {
      setCurrentOption(null);
    }

  }, [selectedAnswers, question.id]);

  return (
    <div className="question-container">
      <div className="qc-question-box">
        <div className="qc-qb-number">
          <p>{question.data.questionNumber}</p>
        </div>
        <div className="qc-qb-text">
          <img src={question.data.url} alt="questionImage" className="qc-qb-t-image" loading="lazy"/>
        </div>
      </div>
      {
        question.data.answers.map((answer, index) => {
          return (
            <Answer 
              answer={answer} 
              questionId={question.id}
              key={index}
              chosen={currentOption === answer.option ? true : false}
            />
          )
        })
      }
    </div>
  );
}

export default Question;