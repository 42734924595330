const calculateSingleType = ({ labels, type, overallData }) => {
  const data = []
 
  if(overallData.length > 0) {
    labels.map((label) => {
      const labelDatas = overallData.filter((e) => e.data.demographicAnswers[type] === label);

      if(labelDatas.length > 0) {
        data.push({
          label,
          data: (labelDatas.reduce((acc, curr) => acc + curr.data.result, 0) / labelDatas.length).toFixed(1)
        })
      }
    })
  }

  return data;
};

export default calculateSingleType;