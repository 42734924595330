import * as AiIcons from "react-icons/ai";

import { useState } from 'react';

import GeneralBar from '../../barChart/generalBar/generalBar';

import MultipleSelectPopup from '../../popup/multipleSelectPopup/multipleSelectPopup';

import CitiesData from '../../../data/cities.json';
import MultipleParametersTable from "../../tables/multipleParametersTable/multipleParametersTable";


const CityCompare = ({ overallData }) => {
  const [ selectedCities, setSelectedCities ] = useState(["tümü"]);
  const [ visibility, setVisibility ] = useState(false);

  const togglePopup = () => {
    setVisibility(!visibility);
  }

  const handleSelect = (city) => {
    if(selectedCities.length >= 4) return alert("En fazla 4 şehir seçilebilir.")
    setSelectedCities([...selectedCities, city]);
    setVisibility(false);
  };

  const handleDelete = (city) => {
    if(selectedCities.length === 1) return alert("En az bir şehir seçili olmalıdır.")
    setSelectedCities(selectedCities.filter((c) => c !== city));
    setVisibility(false);
  }

  return (
    <div className='compare-container'>
      <MultipleSelectPopup 
        visibility={ visibility }
        togglePopup={ togglePopup }
        title={ "Şehir Ekle" }
        data={ CitiesData }
        currentData={ selectedCities }
        handleSelect={ handleSelect }
        handleDelete={ handleDelete }
        allOptionText={ "Türkiye Geneli" }
      />
      <div className='cc-filter-box'>
        <div className='cc-fb-element-box'>
          {selectedCities.map((city, index) => (
            <div key={index} className='cc-fb-element' onClick={() => handleDelete(city)}>
              <p>{city === "tümü" ? "Türkiye Geneli" : city}</p>
            </div>
          ))}
        </div>
        <div className='cc-fb-add-element' onClick={() => togglePopup()}>
          <AiIcons.AiOutlinePlus />
        </div>
      </div>
      <div className='chart-container'>
        <GeneralBar overallData={ overallData } selectedCities={ selectedCities } />
      </div>
      <div className="cc-table-container">
        <MultipleParametersTable 
          currentData={ selectedCities }
          columns={ ["Cinsiyet", "Katılım Sayısı", "Ortalama Puan"] }
          labels={ ["erkek", "kadın", "diğer", "belirtmek istemiyorum"] }
          parameters={ ["city", "gender"] }
          data={ overallData }
        />
      </div>
    </div>
  )
}

export default CityCompare;