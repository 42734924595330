import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menuToggle: false,
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.menuToggle = !state.menuToggle;
    }
  },
});

export const { toggleMenu } = menuSlice.actions;
export default menuSlice.reducer;