import './popup.css';
import * as AiIcons from "react-icons/ai";

const Popup = ({ visibility, children, togglePopup}) => {
  return (
    <div className={`popup-container ${visibility && 'pc-active'}`} >
      <div className='pc-content-box'>
        {children}
      </div>
      <button className='pc-close-button' onClick={() => togglePopup()}>
        <AiIcons.AiOutlineClose size={24}/>
      </button>
    </div>
  );
};

export default Popup;