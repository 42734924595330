import getRandomRGBA from "./getRandomRGBA";
const singleComparison = ({mainType, mainFilter, secondType, overallData, labels}) => {
  const data = {
    labels: mainFilter,
    data: [],
    backgroundColor: [],
    borderWidth: 1,
    color: '#fff'
  }

  for(let i = 0; i < labels.length; i++) {
    const color = getRandomRGBA();
    data.backgroundColor.push(`rgba(${color}, 0.3)`);
  }

  let mainDatas;
  if ( mainFilter === 'tümü') {
    mainDatas = overallData;
  } else {
    mainDatas = overallData.filter((e) => e.data.demographicAnswers[mainType] === mainFilter);
  }

  if(mainDatas.length > 0) {
    labels.map((label) => {
      const labelDatas = mainDatas.filter((e) => e.data.demographicAnswers[secondType] === label);

      if(labelDatas.length > 0) {
        data.data.push((labelDatas.reduce((acc, curr) => acc + curr.data.result, 0) / labelDatas.length).toFixed(1));
      }
    })
  }

  return data;
}

export default singleComparison;