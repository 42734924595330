import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  questions: [],
};

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    addQuestions: (state, action) => {
      state.questions = action.payload;
    }
  },
});

export const { addQuestions } = questionsSlice.actions;
export default questionsSlice.reducer;