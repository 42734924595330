import './personalBar.css';
import React from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(ChartDataLabels);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const labels = ['Test Puanı'];

const PersonalBar = ({ result, avarage }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Test Sonucu',
      },
      datalabels: {
        display: true,
        color: result || avarage === 0 ? 'black' : 'white',
        font: {
          weight: '500',
          size: 20,
        },
      },
    },
  };
  const data = {
    labels,
    datasets: [
      {
        label: "Genel Ortalama",
        data: [(avarage).toFixed(2)],
        backgroundColor: "rgba(255, 97, 135, 0.5)",
      },
      {
        label: "Senin Puanın",
        data: [(result).toFixed(2)],
        backgroundColor: "rgba(165, 59, 218, 0.5)",
      }
    ],
  };
  return (
    <div className='personal-bar-container'>
      <Bar options={options} data={data} />
    </div>
  );
};

export default PersonalBar;