import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  overallResults: [
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'erkek',
          age: '24',
          city: 'kocaeli',
          graduation: 'üniversite',
          parents: 'Lise'
        },
        result: 60,
        createdAt : {seconds: 1660026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'kadın',
          age: '34',
          city: 'amasya',
          graduation: 'üniversite',
          parents: 'Lisans'
        },
        result: 45,
        createdAt : {seconds: 1660026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'kadın',
          age: '25',
          city: 'adana',
          graduation: 'lisans',
          parents: 'Lisans'
        },
        result: 76,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'kadın',
          age: '34',
          city: 'istanbul',
          graduation: 'lisans',
        },
        result: 45,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'diğer',
          age: '24',
          city: 'mersin',
          graduation: 'lisans',
        },
        result: 66,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '24',
          city: 'konya',
          graduation: 'lisans',
        },
        result: 45,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'kadın',
          age: '24',
          city: 'muğla',
          graduation: 'üniversite',
        },
        result: 45,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'erkek',
          age: '34',
          city: 'erzurum',
          graduation: 'lisans',
        },
        result: 94,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'erkek',
          age: '24',
          city: 'eskişehir',
          graduation: 'lisans',
        },
        result: 25,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '24',
          city: 'çanakkale',
          graduation: 'lisans',
        },
        result: 86,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '24',
          city: 'bingol',
          graduation: 'lisans',
        },
        result: 65,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '34',
          city: 'giresun',
          graduation: 'lisans',
        },
        result: 45,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '24',
          city: 'kütahya',
          graduation: 'lisans',
        },
        result: 86,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '24',
          city: 'muş',
          graduation: 'lisans',
        },
        result: 65,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '34',
          city: 'nevşehir',
          graduation: 'lisans',
        },
        result: 45,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '24',
          city: 'uşak',
          graduation: 'üniversite',
        },
        result: 86,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '24',
          city: 'zonguldak',
          graduation: 'lisans',
        },
        result: 65,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '34',
          city: 'tokat',
          graduation: 'lisans',
        },
        result: 45,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'erkek',
          age: '24',
          city: 'trabzon',
          graduation: 'lisans',
        },
        result: 66,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'kadın',
          age: '34',
          city: 'tunceli',
          graduation: 'üniversite',
        },
        result: 45,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'kadın',
          age: '34',
          city: 'şanlıurfa',
          graduation: 'lisans',
        },
        result: 76,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'kadın',
          age: '56',
          city: 'van',
          graduation: 'lisans',
        },
        result: 45,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'diğer',
          age: '19',
          city: 'yozgat',
          graduation: 'lisans',
        },
        result: 66,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '18',
          city: 'aksaray',
          graduation: 'lisans',
        },
        result: 45,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'kadın',
          age: '42',
          city: 'batman',
          graduation: 'üniversite',
        },
        result: 45,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'erkek',
          age: '23',
          city: 'yalova',
          graduation: 'lisans',
        },
        result: 77,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'erkek',
          age: '61',
          city: 'kırıkkale',
          graduation: 'lisans',
        },
        result: 25,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '54',
          city: 'ardahan',
          graduation: 'üniversite',
        },
        result: 86,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '45',
          city: 'osmaniye',
          graduation: 'lisans',
        },
        result: 65,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '34',
          city: 'düzce',
          graduation: 'lisans',
        },
        result: 45,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '24',
          city: 'denizli',
          graduation: 'lisans',
        },
        result: 86,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '22',
          city: 'diyarbakır',
          graduation: 'lisans',
        },
        result: 65,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '31',
          city: 'çankiri',
          graduation: 'lisans',
        },
        result: 45,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '44',
          city: 'bilecik',
          graduation: 'ilkokul',
        },
        result: 86,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '34',
          city: 'sivas',
          graduation: 'lisans',
        },
        result: 65,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
    {
      id: 1,
      data: {
        demographicAnswers: {
          gender: 'belirtmek istemiyorum',
          age: '34',
          city: 'sinop',
          graduation: 'lisans',
        },
        result: 45,
        createdAt : {seconds: 1680026361, nanoseconds: 36000000} 
      }
    },
  ],
};

export const overallResultsSlice = createSlice({
  name: 'overallResults',
  initialState,
  reducers: {
    setOverallResults: (state, action) => {
      state.overallResults = action.payload;
    }
  }
});

export const { setOverallResults } = overallResultsSlice.actions;
export default overallResultsSlice.reducer;
