import getRandomRGBA from "./getRandomRGBA";
const organiseCityCompareData = ({selectedCities, labels, overallData }) => {
  let resultsByGender = [];

  selectedCities.map((city) => {
    const colorRGB = getRandomRGBA();
    resultsByGender.push({
      label: city === 'tümü' ? "Türkiye Geneli" : city,
      data: [],
      borderColor: `rgb(${colorRGB})`,
      backgroundColor: `rgba(${colorRGB}, 0.4)`,
      borderWidth: 1
    })
  })

  resultsByGender.map((datas) => {
    let cityDatas;
    if(datas.label === 'Türkiye Geneli') {
      cityDatas = overallData;
    } else {
      cityDatas = overallData.filter((element) => element.data.demographicAnswers.city === datas.label);
    }
    if(cityDatas.length > 0) {
      labels.map((label) => {
        const g = cityDatas.filter((element) => element.data.demographicAnswers.gender === label);
        if(g.length > 0) {
          datas.data.push((g.reduce((acc, curr) => acc + curr.data.result, 0) / g.length).toFixed(1))
        } else {
          datas.data.push(0)
        }
      })
    }
  })

  return resultsByGender;
}

export default organiseCityCompareData;