import './demographic.css';
import * as FaIcons from "react-icons/fa"; 
import * as AiIcons from "react-icons/ai";
import Questions from '../../data/demographicQuestions.json';
import { useEffect, useState } from 'react'; 
import { useDispatch } from 'react-redux';
import { addDemographicAnswers } from '../../redux/features/resultSlice';

import OpenEndedInput from './openEndedInput/openEndedInput';

const Demographic = () => {
  const dispatch = useDispatch();
  const [answers, setAnswers] = useState({
    studentDegree: null,
    age: null,
    gender: null,
    city: null,
    department: null,
    grade: null,
    gpa: null,
    parents: null,
    mediaStatisticsTrust: null,
    dailyStatisticsReading: null,
  });
  const [search, setSearch] = useState('');

  const handleClick = (question, e) => {
    const answer = answers[question.type];
    if(answer) {
      setAnswers({...answers, [question.type]: e.target.value});
    } else {
      setAnswers({...answers, [question.type]: e.target.value});
    }
  }

  const handleChange = (e) => {
    setAnswers({...answers, [e.target.name]: e.target.value});
  }

  function toTitleCaseTR(str) {
    return str.toLocaleLowerCase('tr-TR').replace(/(?:^|\s|,|;|!|:|-|\.|\?)[a-z0-9ğçşüöı]/g, function(match) {
        return match.toLocaleUpperCase('tr-TR');
    });
  }

  useEffect(() => {
    dispatch(addDemographicAnswers(answers));
  }, [answers])

  return (
    <>
      {Questions.map((question, index) => (
        <div className="demographic-container" key={index}>
          <div className="dc-question-box">
            <p>{question.question}</p>
            {question.answers.length > 10 && 
              <div className='dc-qb-search-box'>
                <input type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} value={search} />
                {search !== "" ? <AiIcons.AiOutlineClose color='gray' style={{cursor: "pointer"}} onClick={() => setSearch('')}/> : <FaIcons.FaSearch color='gray'/>}
              </div>
            }
          </div>
          <div className='dc-answer-container'>
            {search !== " " && question.type === "city"
              ?
                question.answers.map((answer, index) => {
                  if(toTitleCaseTR(answer).includes(toTitleCaseTR(search))) {
                    return (
                      <div className="dc-answer-box" key={index}>
                        <input type="radio" name={question.question} value={answer} id={`${question.question}-${answer}`} onClick={(e) => handleClick(question, e)} />
                        <label htmlFor={`${question.question}-${answer}`}>{answer}</label>
                      </div>
                    )
                  }
                }
              )
              :
                question.isOpenEnded
                ?
                  <OpenEndedInput handleChange={ handleChange } targetName={question.type} inputType={question.inputType}/>
                :
                question.answers.map((answer, index) => {
                  return (
                    <div className="dc-answer-box" key={index}>
                      <input type="radio" name={question.question} value={answer} id={`${question.question}-${answer}`} onClick={(e) => handleClick(question, e)} />
                      <label htmlFor={`${question.question}-${answer}`}>{answer}</label>
                    </div>
                  )
                })
            }
          </div>
        </div>
      ))}
    </>

  )
}

export default Demographic;