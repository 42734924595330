import { useState, useEffect } from 'react'
import MultipleSelectPopup from '../../popup/multipleSelectPopup/multipleSelectPopup';
import { getYears } from '../../../lib/getTypes';
import LineChart from '../../lineChart/lineChart';
import * as AiIcons from "react-icons/ai";

import organiseYearAndMonthData from '../../../lib/organiseYearAndMonthData';

const YearCompare = ({ overallData }) => {
  const [ selectedYears, setSelectedYears ] = useState([String(new Date().getFullYear())]);
  const [ existingYears, setExistingYears ] = useState([]);
  const [ yearData, setYearData ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ visibility, setVisibility ] = useState(false);

  const labels = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

  useEffect(() => {
    setExistingYears(getYears(overallData));
  }, [])

  useEffect(() => {
    if(overallData.length <= 0) return;
    setYearData(organiseYearAndMonthData({ selectedYears, overallData, labels}));
    setLoading(false);
  }, [selectedYears])

  const togglePopup = () => {
    setVisibility(!visibility);
  }

  const handleSelect = (year) => {
    setSelectedYears([...selectedYears, year]);
    setVisibility(false);
  }

  const handleDelete = (year) => {
    if(selectedYears.length === 1) return alert("En az bir yıl seçili olmalıdır.")
    setSelectedYears(selectedYears.filter((y) => y !== year));
    setVisibility(false);
  }

  if(loading) return null

  return (
    <div className='compare-container'>
      <MultipleSelectPopup 
        visibility={ visibility } 
        togglePopup={ togglePopup }
        title={ "Yıl Ekle" }
        data={ existingYears }
        currentData={ selectedYears }
        handleSelect={ handleSelect }
        handleDelete={ handleDelete }
        allOptionText={ "Tüm Yıllar" }
      />
      <div className='cc-filter-box'>
        <div className='cc-fb-element-box'>
          {selectedYears.map((year, index) => (
            <div key={index} className='cc-fb-element' onClick={() => handleDelete(year)}>
              <p>{year === "tümü" ? "Tüm Yıllar" : year}</p>
            </div>
          ))}
        </div>
        <div className='cc-fb-add-element' onClick={() => togglePopup()}>
          <AiIcons.AiOutlinePlus />
        </div>
      </div>
      <div className='chart-container'>
        <LineChart 
          contentData={ yearData }
        />
      </div>
    </div>
  )
}

export default YearCompare