import RatesPieChart from "../ratesPieChart/ratesPieChart"

const RatesChartComponent = ({results, title}) => {
  const keys = Object.keys(results)
  const values = Object.values(results)

  return (
    <div className='rates-pie-chart-container'>
      <div className="rpcc-chart-container">
        <RatesPieChart results={results}/>
      </div>
      <div className="rpcc-text-container">
        <h3 className="pcc-tc-title">{title}</h3>
        <div className="pcc-tc-table-container">
          <table className="pcc-tc-tb-table">
            <tbody className="pcc-tc-tb-t-body">
              {keys.map((key, index) => (
                <tr key={index}>
                  <th className="pcc-tc-tb-t-b-header">{key}</th>
                  <td className="pcc-tc-tb-t-b-data">{values[index]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default RatesChartComponent