import HorizontalBarChart from "../../horizontalBarChart/horizontalBarChart";
import { getCities } from '../../../lib/getTypes';
import calculateSingleType from '../../../lib/calculateSingleType';

import { useState, useEffect } from 'react';
import SingleParameterTable from "../../tables/singleParameterTable/singleParameterTable";

const CitiesResults = ({ overallData }) => {
  const [ loading, setLoading ] = useState(true);
  const [ labels, setLabels ] = useState([]);
  const [ datas, setDatas ] = useState([]);

  useEffect(() => {
    if(overallData.length > 0) {
      const labels = getCities(overallData);
      const citiesData = calculateSingleType({ labels, type: "city", overallData });
      const sortedCitiesData = citiesData.sort((a, b) => b.data - a.data).slice(0, 10);

      setLabels(sortedCitiesData.map((e) => e.label));
      setDatas(sortedCitiesData.map((e) => e.data));

      setLoading(false);
    }
  }, [overallData])

  if(loading) return null

  return (
    <div className="compare-container">
      <p>En yüksek ortalamaya sahip olan ilk 10 şehir</p>
      <div className='chart-container'>
        <HorizontalBarChart 
          labels={ labels } 
          datas={ datas }
          title="Şehirlerin Ortalama Puanları"
          dataLabel="Şehirler"
          color="#FFC107"
        />
      </div>
      <div className="cc-table-container">
        <SingleParameterTable 
          columns={["Şehirler", "Katılım Sayısı", "Ortalama Puan"]}
          labels={labels}
          data={datas}
          overallData={overallData}
          parameter={"city"}
        />
      </div>

    </div>
  )
};

export default CitiesResults;