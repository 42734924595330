import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './redux/store';
import ScrollToTop from './components/scrollToTop/scrollToTop';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store= { store }>
      <ScrollToTop />
      <App />
    </Provider>
  </BrowserRouter>

  // <React.StrictMode>
  //   <BrowserRouter>
  //     <Provider store= { store }>
  //       <App />
  //     </Provider>
  //   </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
