import './home.css'
import TestRedirect from '../../components/testRedirect/testRedirect';
import ParticipantAndRate from '../../components/participantAndRate/participantAndRate';
import Rates from '../../components/rates/rates';

import React, { useEffect, useState } from 'react';
import {collection, query, onSnapshot, getDocs, doc, getDoc } from "firebase/firestore"
import db from '../../firebase/connection'

const Home = () => {
  const [results, setResults] = useState([])
  useEffect(() => {
    // const q = query(collection(db, 'results'))
    // onSnapshot(q, (querySnapshot) => {
    //   setResults(querySnapshot.docs.map(doc => ({
    //     id: doc.id,
    //     data: doc.data()
    //   })))
    // })
    const getResults = async () => {
      const querySnapshot = await getDocs(collection(db, 'results'))
      setResults(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data()
      })))
    }
    getResults()

  }, [])

  return (
    <div className='container-home'>
      <div className='ch-title-box'>
        <p>İstatistik okuryazarlık düzeyinin arttırılması amacıyla hizmetinize sunduğumuz internet sitemize hoşgeldiniz.</p>
      </div>
      <div className='ch-content-box'>
        <TestRedirect />
      </div>
      <div className='ch-content-box'>
        {/* <ParticipantAndRate results={results} /> */}
        {/* {results.length > 0 && <Rates resultsData={ results }/>} */}
        <Rates resultsData={ results }/>
      </div>
    </div>
  );
}

export default Home;