import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: 0,
  toggle: false,
};

export const timerForRatesSlice = createSlice({
  name: 'timerForRates',
  initialState,
  reducers: {
    increaseCount: (state, {payload}) => {
      state.count += 1;
      if(state.count === payload) {
        state.count = 0;
      }
    },
    timerToggle: (state, {payload}) => {
      state.toggle = payload;
    },
  },
})

export const { increaseCount, timerToggle } = timerForRatesSlice.actions;
export default timerForRatesSlice.reducer;