import './App.css';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Home from './pages/home/home';
import About from './pages/about/about';
import Test from './pages/test/test';
import Results from './pages/results/results'
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/navbar';

function App() {
  return (
    <div className="App">
      <div className='body'>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/test" element={<Test />} />
          <Route path="/results" element={<Results />} />
        </Routes>
        <Footer />
      </div>
      <Navbar />
    </div>
  );
}

export default App;
