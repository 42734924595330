import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

function RatesPieChart({ results }) {
  const data = {
    labels: Object.keys(results),
    datasets: [
      {
        label: 'katılım sayısı',
        data: Object.values(results),
        backgroundColor: [
          '#00E8D7',
          '#FF5733',
          '#A2E800',
          '#FFC300',
          '#00DFA5',
          '#FF5733',
        ],
      },
    ],
  };  
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: '#fff',
        font: {
          weight: 'bold',
          size: 15,
        }
      }
    }
  };
  return <Pie data={data} options={options}/>
}

export default RatesPieChart;