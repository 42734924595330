import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const HorizontalBarChart = ({ labels, datas, title, dataLabel, color}) => {
  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: title,
      },
      scales: {
        x: {
          beginAtZero: true,
          max: 100
        }
      }
    },
  };
    
  const data = {
    labels,
    datasets: [
      {
        label: dataLabel,
        data: datas,
        borderColor: color,
        backgroundColor: color+"80",
      }
    ],
  };

  return (
    <Bar options={options} data={data} />
  )
};

export default HorizontalBarChart;