import './ratesSlice.css'
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { increaseCount } from '../../../redux/features/timerForRates';

const RatesProgressContainer = ({ ratesData, index, isMouseDown }) => {
  const { count, toggle } = useSelector(state => state.timerForRates)
  const [seconds, setSeconds] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    if(count === index) {
      let timerInterval;
      if(!toggle) {
        timerInterval = setInterval(() => {
          setSeconds(seconds => seconds + 1)
        }, 100)
      } else {
        setSeconds(seconds)
      }
      return () => clearInterval(timerInterval)
    }
  }, [count,toggle])

  useEffect(() => {
    if(seconds === 100) {
      dispatch(increaseCount(ratesData.length))
      setSeconds(0)
    }
  }, [seconds])
  
  return (
    <div className='rates-progress-container'>
      <div className='rpc-slice'>
        <div className='rpc-slice-progress' style={count === index ? {width: `${seconds}%`} : count > index ? {width: '100%'} : {}}></div>
      </div>
    </div>
  )
}

export default RatesProgressContainer;