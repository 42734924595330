// Shows data according to the year and month information for the line chart.
const organiseYearAndMonthData = ({ labels, existingYears, overallData, selectedYears}) => {
  const data = [];
  const bgColor = [
    "rgba(255, 99, 132, 0.5)",
    "rgba(54, 162, 235, 0.5)",
    "rgba(255, 206, 86, 0.5)",
    "rgba(75, 192, 192, 0.5)",
    "rgba(153, 102, 255, 0.5)",
    "rgba(255, 159, 64, 0.5)",
    "rgba(255, 99, 132, 0.5)",
    "rgba(54, 162, 235, 0.5)",
    "rgba(255, 206, 86, 0.5)",
    "rgba(75, 192, 192, 0.5)",
    "rgba(153, 102, 255, 0.5)",
    "rgba(255, 159, 64, 0.5)",
  ];
  const borderColor = [
    "rgb(255, 99, 132)",
    "rgb(54, 162, 235)",
    "rgb(255, 206, 86)",
    "rgb(75, 192, 192)",
    "rgba(153, 102, 255)",
    "rgba(255, 159, 64)",
    "rgba(255, 99, 132)",
    "rgba(54, 162, 235)",
    "rgba(255, 206, 86)",
    "rgba(75, 192, 192)",
    "rgba(153, 102, 255)",
    "rgba(255, 159, 64)",
  ];
  selectedYears.map((year, index) => {
    data.push({
      label: year === "tümü" ? "Tüm Yıllar" : year,
      data: [],
      backgroundColor: bgColor[index],
      borderColor: borderColor[index],
    });
  });

  data.map((datas) => {
    let yearData;
    if (datas.label === "Tüm Yıllar") {
      yearData = overallData;
    } else {
      yearData = overallData.filter(
        (e) => String(new Date(e.data.createdAt.seconds * 1000 + e.data.createdAt.nanoseconds / 1000000).getFullYear()) === datas.label
      );
    }
    if (yearData.length > 0) {
      labels.map((label) => {
        const g = yearData.filter((e) => String(new Date(e.data.createdAt.seconds * 1000 + e.data.createdAt.nanoseconds / 1000000).getMonth() + 1) === label);
        if (g.length > 0) {
          datas.data.push((g.reduce((acc, curr) => acc + curr.data.result, 0) / g.length).toFixed(1));
        } else {
          if(new Date().getFullYear() === Number(datas.label) && new Date().getMonth() + 1 < Number(label)) return;
          datas.data.push(0);
        }
      });
    }
  });

  return data;

};

export default organiseYearAndMonthData;