import './openEndedInput.css';
const OpenEndedInput = ({handleChange, targetName, inputType}) => {
  return (
    <div className="dc-answer-box">
      <input 
        className='dc-ab-open-ended-input' 
        type={inputType} 
        name={targetName} 
        id="open-ended" 
        placeholder="Cevabınızı buraya girebilirsiniz" 
        onChange={handleChange}
      />
    </div>
  )
}

export default OpenEndedInput;