import './generalBar.css';
import { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import organiseCityCompareData from '../../../lib/organiseCityCompareData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const GeneralBar = ({ overallData, selectedCities }) => {
  const [cityData, setCityData] = useState([]);
  const [loading, setLoading] = useState(true);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' ,
      },
      datalabels: {
        display: true,
        color: 'black',
        anchor: 'end',
        align: 'end',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100
      }
    }
  };
  
  const labels = ['erkek', 'kadın', 'diğer', 'belirtmek istemiyorum'];

  useEffect(() => {
    const resultsByGender = organiseCityCompareData({ selectedCities, labels, overallData });
    if(resultsByGender.length > 0) {
      setCityData(resultsByGender);
      setLoading(false);
    }
  }, [selectedCities])


  const data = {
    labels,
    datasets: cityData,
  };
  return (
    <div className='general-bar-container'>
      {loading 
        ? <div className="loading">Loading...</div> 
        : <Bar data={data} options={options} />
      }
    </div>
  );
};

export default GeneralBar;
