import './testRedirect.css'
import image from "../../assets/testImage.png"
import  { Link } from 'react-router-dom';

const TestRedirect = () => {
  return (
    <div className="container-test-redirect">
      <div className='ctr-image-box'>
        <img src={image} alt="testLogo"/>
      </div>
      <div className='ctr-content-box'>
        <p className='ctr-content-box-title'>İstatistik okuryazarlık ölçme testi</p>
        <p className='ctr-content-box-description'>
        Hangi seviyede istatistiksel okuryazar olduğunuzu sınamaya yarayacak bu kısa testi  (Şahin, 2012, s.126) 
        yanıtlayarak istatistik okuryazarlık notunuzu öğrenebilir ve grafikle birlikte seviyenizi genel sonuçla kıyaslayabilirsiniz.
        </p>
        <Link className='ctr-button' to="/test">Teste Başla</Link>
        <div className='ctr-cb-reference-container'>
          <p className='ctr-cb-rc-title'>Şahin, F. (2012). A study for development of statistical literacy scale for undergraduate students Doctoral dissertation, Boğaziçi University. Retrieved from: </p>
          <a className='ctr-cb-rc-link' href='https://toad.halileksi.net/sites/default/files/pdf/lisans-ogrencileri-icin-istatistiksel-okuryazarlik-olcegi-toad.pdf' target='__blank'>
            TOAD
          </a>
        </div>
      </div>
    </div>
  );
}

export default TestRedirect;