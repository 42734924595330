import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedAnswers: [],
};

export const selectedAnswersSlice = createSlice({
  name: 'selectedAnswers',
  initialState,
  reducers: {
    toggleAnswers: (state, action) => {
      const { questionId, option } = action.payload;
      const answer = state.selectedAnswers.find((answer) => answer.questionId === questionId);
      if (answer) {
        const index = state.selectedAnswers.indexOf(answer);
        if (answer.option === option) {
          state.selectedAnswers.splice(index, 1);
        } else {
          state.selectedAnswers[index].option = option;
        }
      } else {
        state.selectedAnswers.push({ questionId, option });
      }
    },
    clearAnswers: (state) => {
      state.selectedAnswers = [];
    },
  },
});

export const { toggleAnswers, clearAnswers } = selectedAnswersSlice.actions;
export default selectedAnswersSlice.reducer;