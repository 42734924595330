import "./header.css";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  return (
    <header>
      <div className="header">
        <div className="hd-side-container-title">
          <Link to="/" className="hd-sd-ct-link"><h2>İOY</h2></Link>
        </div>
        <div className="hd-menu-container">
          <ul className="hd-menu-container-ul">
            <li>
              <NavLink to="/" className="hd-link">Anasayfa</NavLink>
            </li>
            <li>
              <Link to="/results" className="hd-link">Çalışma Sonuçları</Link>
            </li>
            {/* <li>
              <Link to="/about" className="hd-link">Kaynaklar</Link>
            </li>
            <li>
              <Link to="/about" className="hd-link">Bilgi Bankası</Link>
            </li> */}
          </ul>
        </div>
        <div className="hd-side-container-menu">
        </div>
      </div>
    </header>
  );
} 

export default Header;