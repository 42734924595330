import HorizontalBarChart from "../../horizontalBarChart/horizontalBarChart";
import { getParentsEducation } from '../../../lib/getTypes';
import calculateSingleType from '../../../lib/calculateSingleType';

import { useState, useEffect } from 'react';
import SingleParameterTable from "../../tables/singleParameterTable/singleParameterTable";

const ParentsResults = ({ overallData }) => {
  const [ loading, setLoading ] = useState(true);
  const [ labels, setLabels ] = useState([]);
  const [ datas, setDatas ] = useState([]);

  useEffect(() => {
    if(overallData.length > 0) {
      const labels = getParentsEducation(overallData);
      const parentsData = calculateSingleType({ labels, type: "parents", overallData });
      const sortedParentsData = parentsData.sort((a, b) => b.data - a.data).slice(0, 10);

      setLabels(sortedParentsData.map((e) => e.label));
      setDatas(sortedParentsData.map((e) => e.data));

      setLoading(false);
    }
  }, [overallData])

  if(loading) return null

  return (
    <div className="compare-container">
      <p>Bu grafikte ebeveyn eğitim düzeylerine göre alınan ortalama puanları inceleyebilirsiniz. </p>
      <div className='chart-container'>
        <HorizontalBarChart 
          labels={ labels } 
          datas={ datas }
          title="Ebeveynlerin Eğitim Düzeylerine Göre Ortalama Puanları"
          dataLabel="Ebeveyn Eğitim Düzeyi"
          color="#FF5733"
        />
      </div>
      <div className="cc-table-container">
        <SingleParameterTable 
          columns={["Ebeveyn Öğrenim Durumu", "Katılım Sayısı", "Ortalama Puan"]}
          labels={labels}
          data={datas}
          parameter={"parents"}
          overallData={overallData}
        />
      </div>

    </div>
  )
};

export default ParentsResults;