import '../tables.css';
import { useState, useEffect } from 'react';
const MultipleParametersTable = ({ currentData, labels, columns,parameters, data }) => {
  const [ tableData, setTableData ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    let b = [];

    currentData.forEach((cData) => {
      b.push({
        currentData: cData,
        datas: []
      })
    });

    b.forEach(bData => {
      let a;
      if(bData.currentData === "tümü") {
        a = data;
      } else {
        a = data.filter((e) => e.data.demographicAnswers[parameters[0]] === bData.currentData)
      }
      labels.forEach(label => {
        const length = a.filter((e) => e.data.demographicAnswers[parameters[1]] === label).length;
        let score = 0;
        if(length > 0) {
          score = ((a.filter((e) => e.data.demographicAnswers[parameters[1]] === label)).reduce((acc, curr) => acc + curr.data.result, 0) / a.filter((e) => e.data.demographicAnswers[parameters[1]] === label).length).toFixed(1);
        }
        bData.datas.push({
          label: label,
          length: length,
          score: score
        })
      });
    });

    setTableData(b);
    setLoading(false);
  }, [currentData, data, labels, parameters])

  if(loading) return null;

  return (
    <div className='table-container'>
      {tableData.map((data, i) => (
        <table key={i}>
          <thead>
            <tr>
              <th colSpan={columns.length}>{data.currentData}</th>
            </tr>
          </thead>
          <tbody>
            <tr className='tc-columns'>
              {columns.map((column, i) => (
                <th key={i}>{column}</th>
              ))}
            </tr>
            {data.datas.map((row, i) => (
              <tr key={i}>
                <td>{row.label}</td>
                <td>{row.length}</td>
                <td>{row.score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ))}
    </div>
  )
}

export default MultipleParametersTable;