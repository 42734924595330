import '../tables.css'
import { useState, useEffect } from 'react';
const SingleParameterTable = ({ columns, data, labels, parameter, overallData }) => {
  const [ loading, setLoading ] = useState(true);
  const [ tableData, setTableData ] = useState([]);

  useEffect(() => {
    const lengths = [];
    labels.map((label) => {
      lengths.push(overallData.filter((e) => e.data.demographicAnswers[parameter] === label).length)
    })
    setTableData(lengths);
    setLoading(false);
  }, [])

  if(loading) return null;

  return (
    <div className='table-container-single'>
      <table>
        <tbody>
          <tr className='tc-columns-single'>
            {columns.map((column, i) => (
              <th key={i}>{column}</th>
            ))}
          </tr>
          {labels.map((label,i) => (
            <tr key={i}>
              <td>{label}</td>
              <td>{tableData[i]}</td>
              <td>{data[i]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default SingleParameterTable;